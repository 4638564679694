<template>
  <v-container>
    This is the students page - when a person purchases a course - they become a student.
    - What happens when a student purchases another course later on - first and last name? - Bad idea but may be the only way
    - This page will also have access to show if they payed for the course
    - We can add students here
  </v-container>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>

</style>
